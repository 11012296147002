import React from "react";
import BackendService from "../BackendService";
import dayjs from "dayjs";

import { Switch, Select, DatePicker } from "antd";
import * as _ from "lodash";
import { observer } from "mobx-react";
import NotificationComponentStore from "./NotificationComponentStore";
import { withTranslation } from "react-i18next";

class NotificationWeeklyReportSettingsComponent extends React.Component {
  graphqlApi = new BackendService("graphql");
  usersApi = new BackendService("users");
  configApi = new BackendService("configs");

  store = NotificationComponentStore;

  render() {
    return (
      <div>
        <div style={{ marginBottom: "10px" }}>
          <div style={{ display: "inline", verticalAlign: "middle", marginRight: "10px" }}>{this.props.t("notifications.sendWeeklySummary")}</div>
          <div style={{ display: "inline" }}>
            <Switch checked={this.store.weeklyReportSettings.isEnabled} onChange={(r) => (this.store.weeklyReportSettings.isEnabled = r)} />
          </div>
        </div>
        {this.store.weeklyReportSettings.isEnabled === true && (
          <div>
            <div style={{ marginBottom: "10px" }}>
              <div style={{ display: "inline", verticalAlign: "middle", marginRight: "10px" }}>{this.props.t("notifications.startDate")}</div>
              <div style={{ display: "inline" }}>
                <DatePicker
                  value={this.store.weeklyReportSettings.startDate ? dayjs(this.store.weeklyReportSettings.startDate, "YYYY/MM/DD HH") : undefined}
                  onChange={(e) =>
                    (this.store.weeklyReportSettings.startDate = `${_.padStart(e.year(), 4, 0)}-${_.padStart(e.month() + 1, 2, 0)}-${_.padStart(e.date(), 2, 0)} ${_.padStart(e.hours(), 2, 0)}:${_.padStart(e.minutes(), 2, 0)}`)
                  }
                  bordered={false}
                  showTime={{ format: "HH" }}
                  format="YYYY-MM-DD HH"
                />
              </div>
            </div>
            <div style={{ marginBottom: "10px" }}>
               {this.props.t("notifications.repeatEvery")} {" "}
              <Select style={{ minWidth: 40 }} onChange={(r) => (this.store.weeklyReportSettings.repeatAmount = r)} value={this.store.weeklyReportSettings.repeatAmount} bordered={false}>
                {Array.from(Array(31).keys()).map((i, index) => (
                  <Select.Option value={index + 1} key={index}>
                    {index + 1}
                  </Select.Option>
                ))}
              </Select>{" "}
              <Select style={{ minWidth: 120 }} onChange={(r) => (this.store.weeklyReportSettings.repeatTimeFrame = r)} value={this.store.weeklyReportSettings.repeatTimeFrame} bordered={false}>
                <Select.Option value="day">{this.props.t("notifications.days")}</Select.Option>
                <Select.Option value="week">{this.props.t("notifications.weeks")}</Select.Option>
                <Select.Option value="month">{this.props.t("notifications.months")}</Select.Option>
              </Select>
            </div>
            {this.store.weeklyReportSettings.repeatTimeFrame === "week" && (
              <div style={{ marginBottom: "10px" }}>
                <div style={{ display: "inline", verticalAlign: "middle", marginRight: "10px" }}>{this.props.t("notifications.repeatWeekDays")}</div>
                <div style={{ display: "inline" }}>
                  <Select mode="multiple" allowClear style={{ width: "50%" }} placeholder={this.props.t("notifications.selectDays")} onChange={(r) => (this.store.weeklyReportSettings.repeatDays = r)} value={this.store.weeklyReportSettings.repeatDays}>
                    {["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].map((x, index) => (
                      <Select.Option value={index}>{x}</Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(observer(NotificationWeeklyReportSettingsComponent));
