import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";

// import "leaflet/dist/leaflet.css";

import ConfigStore from "./Common/ConfigStore";

const LayoutEmpty = React.lazy(() => import("./Common/LayoutEmpty/LayoutEmpty"));
const EmptyPage = React.lazy(() => import("./EmptyPage"));

// I18N
import "moment/locale/es";
import "moment/locale/en-gb";
import "moment/locale/pl";
import "moment/locale/pt";

import enMessages from "devextreme/localization/messages/en.json";
import esMessages from "devextreme/localization/messages/es.json";
import plMessages from "devextreme/localization/messages/pl.json";
import ptMessages from "devextreme/localization/messages/pt.json";

import { last } from "lodash";
import { Spin } from "antd";
import { loadMessages, locale } from "devextreme/localization";
import { useTranslation } from "react-i18next";
import moment from "moment";
import dayjs from "dayjs";

import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import routes from "./routes";

const App = ({ t }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [appConfig, setAppConfig] = useState(undefined);
  const match = useRouteMatch();

  const history = useHistory();
  const { i18n } = useTranslation();
  useEffect(() => {
    loadMessages(enMessages);
    loadMessages(esMessages);
    loadMessages(plMessages);
    loadMessages(ptMessages);

    const languageSet = localStorage.getItem(`language`) || i18n.language;
    i18n.changeLanguage(languageSet);
    locale(languageSet);
    dayjs.locale(languageSet);
    moment.locale(languageSet);
    if (!localStorage.getItem(`language`)) localStorage.setItem(`language`, languageSet);

    fetch("appConfig.json")
      .then(r => r.json())
      .then(appConfigJson => {
        const defaultRoute = JSON.parse(localStorage.getItem(`projectMenu`));
        const existDefaultRoute = defaultRoute?.filter(route => route.defaultRoute) || [];
        const copyConfigJson = {
          ...appConfigJson,
          router: {
            ...appConfigJson.router,
            defaultRoute: existDefaultRoute.length
              ? existDefaultRoute[0]?.defaultRoute
              : appConfigJson.router.defaultRoute
          }
        };
        localStorage.setItem("appConfig", JSON.stringify(copyConfigJson));
        setAppConfig(copyConfigJson);

        if (ConfigStore.id) {
          window.location.replace(ConfigStore.getDefaultRoute());
        }

        localStorage.setItem("appConfig", JSON.stringify(appConfigJson));
        setAppConfig(appConfigJson);
        if (window.location.href.includes(`postlogin`)) {
          var token = last(window.location.href.split("/")).replace(`#`, ``);
          localStorage.setItem(`jwtToken`, token);

          localStorage.removeItem(`projectId`);
          localStorage.removeItem(`tenantId`);

          history.push("/");
          return window.location.reload();
        } else setIsLoading(false);
      });
  }, [ConfigStore.currentMenu, ConfigStore.id]);

  if (isLoading) {
    return (
      <div style={{ marginTop: "20%" }}>
        <div style={{ textAlign: "center" }}>
          <img
            src={"./Beawre_login_logo.png"}
            alt="Beawre"
            style={{ height: "42px", marginBottom: 30, marginTop: 40 }}
          />
        </div>
        <Spin spinning={true} size="large">
          <div style={{ height: 50, width: 50 }}></div>
        </Spin>
      </div>
    );
  }

  return (
    <Switch>
      {routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            render={props => {
              return <route.element {...props} />;
            }}
          />
        );
      })}
      <Route path="/404" component={EmptyPage} layout={LayoutEmpty} />
      <Redirect to="/404" />
    </Switch>
  );
};

export default withTranslation()(observer(App));
