import React from "react";
import { observer } from "mobx-react";
import { Button } from "antd";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import NotificationComponentStore from "./NotificationComponentStore";

const NotificationsHeaderSide = ({ t }) => {
  return (
    <Button
      type="text"
      variant="filled"
      onClick={() =>
        (NotificationComponentStore.settingsModalVisible = !NotificationComponentStore.settingsModalVisible)
      }
    >
      {t("notifications.settings")}
    </Button>
  );
};

export default withTranslation()(observer(NotificationsHeaderSide));
