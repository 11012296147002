import {
  BellOutlined,
  BookOutlined,
  BuildOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  FormOutlined,
  GlobalOutlined,
  LockOutlined,
  LogoutOutlined,
  MinusOutlined,
  PlusOutlined,
  QrcodeOutlined,
  QuestionOutlined,
  SaveOutlined,
  SearchOutlined,
  SettingOutlined
} from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Popconfirm,
  Select,
  Tooltip,
  Popover,
  Flex,
  Space
} from "antd";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { QRCode } from "react-qrcode-logo";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { getIcon as getBookmarkIcon } from "../Common/Bookmarks/BookmarksBar";
import BookmarksStore from "../Common/Bookmarks/BookmarksStore";
import GraphQlService from "../Common/GraphQlService";
import useThemeSwitcher from "../Common/Layout/hooks/useThemeSwitcher";
import NotificationComponentStore from "../Common/Notifications/NotificationComponentStore";
import UserStore from "../Common/Users/UserStore";
import { logout } from "./../Common/Users/Logout";
import ProjectsMenu from "./ProjectsMenu";
import useUserPermissions from "../Settings/hooks/useUserPermissions";
import { findIndex, isNil } from "lodash";
import NotificationComponent from "../Common/Notifications/NotificationComponent";
import NotificationsHeaderSide from "../Common/Notifications/NotificationsHeaderSide";

const BookmarkListItem = ({ item, i, t }) => {
  const [editBookmark, setEditBookmark] = useState(false);
  const [label, setLabel] = useState(item.label);
  const [icon, setIcon] = useState(item.icon);

  const handleCancel = () => {
    // restore default values
    setLabel(item.label);
    setIcon(item.icon);

    setEditBookmark(!editBookmark);
  };

  const handleSave = () => {
    if (label !== item.label || icon !== item.icon) {
      BookmarksStore.mutateBookmarks("edit", {
        ...item,
        label,
        icon
      });
    } else {
      setEditBookmark(!editBookmark);
    }
  };

  const handleDelete = () => {
    BookmarksStore.mutateBookmarks("delete", item);

    if (!BookmarksStore.bookmarks.length) {
      BookmarksStore.showEditBookmarksDialog = false;
      BookmarksStore.showBookmarks = false;
      localStorage.setItem("showAlwaysBookmarks", false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        minWidth: "100%",
        alignItems: "center",
        marginBottom: 5
      }}
    >
      {editBookmark ? (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Input
            placeholder="Title"
            style={{ width: "80%", marginBottom: 5 }}
            size="small"
            value={label}
            onChange={e => setLabel(e.target.value)}
          />
          <Select
            size="small"
            style={{ width: "80%", marginBottom: 5 }}
            onChange={value => setIcon(value)}
            value={icon}
            options={[
              {
                value: "FormOutlined",
                label: <FormOutlined />,
                key: "FormOutlined"
              },
              {
                value: "SearchOutlined",
                label: <SearchOutlined />,
                key: "SearchOutlined"
              },
              {
                value: "BookOutlined",
                label: <BookOutlined />,
                key: "BookOutlined"
              }
            ]}
          />
          <Input disabled style={{ width: "80%" }} size="small" defaultValue={item.pathname + item.search} />
        </div>
      ) : item.label.length > 32 ? (
        item.label.slice(0, 32) + "..."
      ) : (
        item.label
      )}
      <div style={{ display: "flex", alignItems: "center" }}>
        {editBookmark ? (
          <>
            <Tooltip title={t("userInfo.save")}>
              <Button type="text" onClick={() => handleSave()} icon={<SaveOutlined />} />
            </Tooltip>
            <Tooltip title={t("userInfo.cancel")}>
              <Button type="text" onClick={() => handleCancel()} icon={<CloseOutlined />} />
            </Tooltip>
          </>
        ) : (
          <>
            <Tooltip placement="left" title={t("userInfo.editBookmark")}>
              <Button type="text" onClick={() => setEditBookmark(!editBookmark)} icon={<EditOutlined />} />
            </Tooltip>
            <Tooltip placement="right" title={t("userInfo.deleteBookmark")}>
              <Popconfirm
                placement="top"
                title={t("userInfo.deleteConfirm")}
                onConfirm={() => handleDelete()}
                okText={t("yes")}
                cancelText={t("no")}
              >
                <Button type="text" icon={<DeleteOutlined />} />
              </Popconfirm>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  );
};

const UserInfo = ({ t, history, pageTitle }) => {
  const graphQlService = new GraphQlService();
  const { switchTheme } = useThemeSwitcher();

  const { user } = UserStore;

  const [qrCode, setQrCode] = useState({ isVisible: false, isLoading: false, content: undefined });
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [bookmarkLabel, setBookmarkLabel] = useState("");
  const [bookmarkIcon, setBookmarkIcon] = useState("");

  const [changePassword, setChangePassword] = useState({ isVisible: false });

  const projectSettingPermission = useUserPermissions("projectSettings");

  const [userMenuItems, setUserMenuItems] = useState([]);

  const showAddModal = () => {
    setIsAddModalOpen(true);
  };

  const handleOk = () => {
    setIsAddModalOpen(false);

    const newBookmark = {
      id: uuidv4(),
      label: bookmarkLabel || pageTitle,
      icon: bookmarkIcon || "BookOutlined",
      pathname: window.location.pathname + window.location.hash,
      search: window.location.search
    };

    BookmarksStore.mutateBookmarks("add", newBookmark);
  };

  const handleCancel = () => {
    setIsAddModalOpen(false);
  };

  const getRandomColor = () => {
    const colors = ["#ff790E", "#364E65", "#3796f6", "#EC547A", "#90A8BE", "#5BCEAE"];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const getInitials = username => {
    if (username === undefined || username === null) return "##";
    return username
      .split(" ")
      .map(word => word.substr(0, 1).toUpperCase())
      .join("");
  };

  const getQrCode = () => {
    setQrCode(prev => ({ ...prev, isVisible: true, isLoading: true }));
    graphQlService.get(`{ authCollectQrCode }`).then(r => {
      setQrCode(prev => ({ isVisible: true, isLoading: false, content: r.data.authCollectQrCode }));
    });
  };

  const name = useMemo(() => {
    return user?.username ?? "";
  }, [user]);

  const menuItems = [
    {
      label: (
        <p style={{ padding: "0px 12px", marginLeft: 2, marginBottom: 0 }}>
          {name && name?.length >= 16
            ? `${t("userInfo.hi")}  ${getInitials(name)}`
            : `${t("userInfo.hi")}  ${user?.username}`}
        </p>
      ),
      type: "group"
    },
    {
      type: "divider",
      key: "divider"
    },
    {
      label: <div onClick={() => setChangePassword(() => ({ isVisible: true }))}>{t("userInfo.changePassword")}</div>,
      key: "change_pass",
      icon: <LockOutlined onClick={() => setChangePassword(() => ({ isVisible: true }))} />
    },
    {
      label: <div onClick={() => getQrCode()}>{t("userInfo.mobileAccess")}</div>,
      key: "qr_code",
      icon: <QrcodeOutlined />
    },
    {
      label: (
        <a href="https://help.beawre.com" target="_blank">
          {t("userInfo.help")}
        </a>
      ),
      key: "help",
      icon: <QuestionOutlined />
    },
    {
      label: <div onClick={() => switchTheme()}>{t("userInfo.switchTheme")}</div>,
      key: "dark-theme",
      icon: (
        <span role="img" aria-label="Dark/light" style={{ marginLeft: -3 }}>
          🌓
        </span>
      )
    },
    {
      label: t("userInfo.language"),
      key: "languages",
      icon: <GlobalOutlined />,
      children: UserStore.languages.map(({ code, name }) => ({
        label: (
          <div
            onClick={() => {
              localStorage.setItem(`language`, code);
              window.location.reload();
            }}
          >
            {name}
          </div>
        ),
        key: `setlanguage_${code}`,
        icon: <MinusOutlined style={{ fontSize: 7 }} />
      }))
    },
    {
      type: "divider"
    },
    {
      label: (
        <Button type="text" onClick={() => logout(history)} style={{ padding: "0px 10px" }}>
          <span style={{ marginLeft: 0 }}> {t("userInfo.logout")}</span>
        </Button>
      ),
      key: "logout",
      icon: <LogoutOutlined style={{ color: "red" }} />
    }
  ];

  const bookmarksMenuItems = [
    BookmarksStore.bookmarks.length && {
      label: (
        <div onClick={() => BookmarksStore.toggleShowBookmarks()}>
          {BookmarksStore.showBookmarks ? t("userInfo.hideBookmarks") : t("userInfo.showBookmarks")}{" "}
        </div>
      ),
      key: "bookmark-visibility",
      icon: BookmarksStore.showBookmarks ? <EyeInvisibleOutlined /> : <EyeOutlined />
    },
    {
      label: <div onClick={() => showAddModal()}>{t("userInfo.addToBookmarks")}</div>,
      key: "bookmark-new",
      icon: <PlusOutlined />
    },
    BookmarksStore.bookmarks.length && {
      label: <div onClick={() => (BookmarksStore.showEditBookmarksDialog = true)}>{t("userInfo.editBookmarks")}</div>,
      key: "bookmark-edit",
      icon: <EditOutlined />
    },
    {
      type: "divider"
    },
    ...BookmarksStore.bookmarks.map(item => {
      const BookmarkIcon = getBookmarkIcon(item.icon);

      return {
        label: (
          <Link to={{ pathname: item.pathname, search: item.search }}>
            {item.label.length > 18 ? item.label.slice(0, 18) + "..." : item.label}
          </Link>
        ),
        key: item.key,
        icon: <BookmarkIcon />
      };
    })
  ];

  /*   userMenuItems.push({
    label: (
      <Dropdown menu={{ items: bookmarksMenuItems }} placement="bottom" trigger={"click"}>
        <div style={{ cursor: "pointer" }}>
          {" "}
          {BookmarksStore.showBookmarks ? (
            <StarFilled style={{ marginRight: -10 }} />
          ) : (
            <StarOutlined style={{ marginRight: -10 }} />
          )}
        </div>
      </Dropdown>
    ),
    key: "bookmarks"
  }); */

  useEffect(() => {
    const tempMenuItems = [];

    tempMenuItems.push({
      label: <ProjectsMenu />
    });

    if (
      (projectSettingPermission === "view" && findIndex(userMenuItems, v => v.key === "settings") === -1) ||
      UserStore.user?.version === -1
    )
      tempMenuItems.push({
        label: (
          <Link to={`/utils/project/${localStorage.getItem(`projectId`)}`}>
            <SettingOutlined style={{ marginLeft: 0 }} />
          </Link>
        ),
        key: "settings"
      });

    tempMenuItems.push({
      label: (
        <Popover
          trigger="click"
          placement="bottomLeft"
          content={() => (
            <Space style={{ width: "70vw" }} direction="vertical">
              <Flex justify="flex-end">
                <NotificationsHeaderSide />
              </Flex>
              <NotificationComponent />
            </Space>
          )}
        >
          <Badge style={{ float: "right" }} count={NotificationComponentStore.unreadCount}>
            <BellOutlined />
          </Badge>
        </Popover>
      ),
      key: "notifications"
    });

    if (localStorage.getItem(`isSuperUser`) === `true`)
      tempMenuItems.push({
        label: (
          <Dropdown
            menu={{
              items: [
                {
                  label: (
                    <Link to={`/utils/project/${localStorage.getItem(`projectId`)}/projectSettings_pages`}>
                      View builder
                    </Link>
                  ),
                  key: "view_builder"
                },
                {
                  label: (
                    <Link to={`/utils/project/${localStorage.getItem(`projectId`)}/projectSettings_assetDefinition`}>
                      Asset definitions
                    </Link>
                  ),
                  key: "asset_defs"
                },
                {
                  label: (
                    <Link to={`/utils/project/${localStorage.getItem(`projectId`)}/projectSettings_menu`}>
                      Navbar builder
                    </Link>
                  ),
                  key: "menu_builder"
                }
              ]
            }}
            placement="bottomRight"
          >
            <div>
              <BuildOutlined style={{ marginLeft: 0 }} />
            </div>
          </Dropdown>
        ),
        key: "utils_menu"
      });

    tempMenuItems.push({
      label: (
        <Dropdown menu={{ items: menuItems }} placement="bottomLeft">
          <div>
            <Avatar
              style={{
                backgroundColor: getRandomColor()
              }}
              size="small"
            >
              {getInitials(user?.username)}
            </Avatar>
          </div>
        </Dropdown>
      ),
      key: "avatar"
    });

    setUserMenuItems(tempMenuItems);
  }, [projectSettingPermission, user]);

  return (
    <div>
      {user && (
        <Menu
          mode="horizontal"
          style={{ lineHeight: "44px", marginRight: -20, minWidth: 200, background: "transparent" }}
          items={userMenuItems}
        />
      )}

      <Modal
        title={t("userInfo.mobileAccessQRCode")}
        open={qrCode.isVisible}
        footer={null}
        width="80%"
        closable={true}
        maskClosable={true}
        destroyOnClose={true}
        onCancel={() => setQrCode(prev => ({ ...prev, isVisible: false }))}
      >
        {qrCode.content && (
          <div style={{ textAlign: "center" }}>
            <QRCode
              value={qrCode.content}
              ecLevel="H"
              size={800}
              logoImage="/Beawre_logo_header.png"
              logoHeight={48}
              logoWidth={200}
              removeQrCodeBehindLogo={true}
              logoOpacity={0.9}
            />
          </div>
        )}
      </Modal>

      <Modal title={t("userInfo.addNewBookmark")} open={isAddModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
          <Form.Item label={t("userInfo.bookmarkTitle")}>
            <Input
              size="small"
              placeholder={t("userInfo.enterBookmarkTitle")}
              onChange={e => setBookmarkLabel(e.target.value)}
            />
          </Form.Item>

          <Form.Item label={t("userInfo.bookmarkIcon")}>
            <Select
              size="small"
              placeholder={t("userInfo.selectAnIcon")}
              onChange={value => setBookmarkIcon(value)}
              options={[
                {
                  value: "FormOutlined",
                  label: <FormOutlined />,
                  key: "FormOutlined"
                },
                {
                  value: "SearchOutlined",
                  label: <SearchOutlined />,
                  key: "SearchOutlined"
                },
                {
                  value: "BookOutlined",
                  label: <BookOutlined />,
                  key: "BookOutlined"
                }
              ]}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={t("userInfo.editBookmarks")}
        open={BookmarksStore.showEditBookmarksDialog}
        closable={false}
        style={{ width: "100%" }}
        footer={[
          <Button type="primary" onClick={() => (BookmarksStore.showEditBookmarksDialog = false)}>
            {t("userInfo.close")}
          </Button>
        ]}
      >
        <div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {BookmarksStore.bookmarks.map((item, i) => (
              <BookmarkListItem item={item} key={item.label} i={i} t={t} />
            ))}
          </div>
        </div>
      </Modal>

      <Modal
        title={t("userInfo.changePassword")}
        open={changePassword.isVisible}
        closable={true}
        maskClosable={true}
        style={{ width: "90%" }}
        onOk={() => {
          setChangePassword(c => ({ ...c, isLoading: true }));
          graphQlService
            .post(`mutation post($data: ChangePasswordCommand) { userChangePassword(data: $data) }`, {
              data: { password: changePassword.password }
            })
            .then(r => {
              if (r.errors) {
                graphQlService.displayErrors(r.errors);
              } else message.success(`Password has been changed!`);
              setChangePassword(() => ({ isVisible: r.errors ? true : false, isLoading: false }));
            });
        }}
        onCancel={() => setChangePassword(() => ({ isVisible: false, isLoading: false }))}
        confirmLoading={changePassword.isLoading}
      >
        <Input.Password
          value={changePassword.password}
          onChange={e => setChangePassword(c => ({ ...c, password: e.target.value }))}
          placeholder={t("userInfo.password")}
          style={{ width: "100%" }}
        />
      </Modal>
    </div>
  );
};

export default observer(UserInfo);
