import i18next from "i18next";

export const getNotificationsName = t => ({
  comment_new_comment: i18next.t("notifications.newComment"),
  comment_weekly: i18next.t("notifications.riskComment"),
  document_projected_deadline_changed: i18next.t("notifications.deadline"),
  document_new: i18next.t("notifications.newDoc"),
  document_risk_status_changed: i18next.t("notifications.riskStatus"),
  service_account: i18next.t("notifications.serviceAccount"),
  client_review_deadline_violation: i18next.t("notifications.clientReview"),
  subcontractor_is_late: i18next.t("notifications.subcontractor"),
  suggestedAction_new_suggestedAction: i18next.t("notifications.suggestedAction")
});
export const notificationsForm = ["comment", "documents", "suggestedActions", "global", "general"];
export const notificationsSettings = t => ({
  comment: i18next.t("notifications.comment"),
  documents: i18next.t("notifications.documents"),
  suggestedActions: i18next.t("notifications.suggestedActions"),
  global: i18next.t("notifications.global"),
  general: i18next.t("notifications.general")
});
