import { BellOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Checkbox,
  Dropdown,
  List,
  Modal,
  Spin,
  Switch,
  Table,
  Tabs,
  Flex,
  Popconfirm,
  Typography,
  Tooltip
} from "antd";
import { isEmpty, head, keys } from "lodash";
import { DeleteOutlined } from "@ant-design/icons";

import { toJS } from "mobx";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import moment from "moment";

import { Utils } from "@react-awesome-query-builder/antd";
import { forkJoin } from "rxjs";
import BackendService from "../BackendService";
import GraphQlService from "../GraphQlService";
import NotificationComponentStore from "./NotificationComponentStore";
import NotificationWeeklyReportSettingsComponent from "./NotificationWeeklyReportSettingsComponent";
import { withTranslation } from "react-i18next";
import { themeConfig } from "../Layout/config";
import { getNotificationsName, notificationsForm, notificationsSettings } from "./constant";
import useLanguageEffect from "../hook/useLanguageEffect";
import "../../assets/scss/main.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const NotificationComponent = ({ t }) => {
  const [notificationLIst, setNotificationList] = useState({});
  const { currentTheme } = themeConfig();
  const [notificationConfigs, setNotificationConfigs] = useState(undefined);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const config = await fetch("NotificationTypesDictionary.json").then(r => r.json());
    setNotificationConfigs(config);

    NotificationComponentStore.loadList();
  };

  const updateNotificationList = () => {
    const notificationsName = getNotificationsName(t);
    const updatedNotificationList = {};

    notificationsForm.forEach(el => {
      updatedNotificationList[el] = NotificationComponentStore.configs?.[el]?.map(config => {
        return {
          ...config,
          name: notificationsName[config.key]
        };
      });
    });

    setNotificationList(updatedNotificationList);
  };

  useLanguageEffect(() => {
    if (NotificationComponentStore.configs) {
      updateNotificationList();
    }
  }, [NotificationComponentStore.configs]);

  const updateNotificationValue = (parentKey, value, key, property) => {
    setNotificationList(notification => {
      notification[parentKey].forEach(el => {
        if (el.key === key) {
          el[property] = !value;
        }
      });
      return notification;
    });
  };

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAllChange = e => {
    const checked = e.target.checked;
    setSelectAll(checked);
    if (checked) {
      setSelectedItems(NotificationComponentStore.notifications.map(item => item.id));
    } else {
      setSelectedItems([]);
    }
  };

  const handleItemChange = id => e => {
    const checked = e.target.checked;
    setSelectedItems(prev => {
      const newSelectedItems = checked ? [...prev, id] : prev.filter(itemId => itemId !== id);
      setSelectAll(newSelectedItems.length === NotificationComponentStore.notifications.length);
      return newSelectedItems;
    });
  };

  const getText = item => {
    var config = notificationConfigs["Types"].find(x => x.Type === item.payload.Type);
    if (!config) return item.payload.Values?.Value1;

    var props = {};
    config["TextParameters"].forEach(p => {
      var sourceSplit = p.source.split(".");
      var itemP = item;
      sourceSplit.forEach(e => {
        itemP = itemP[e];
      });
      props[p.key] = itemP;
    });
    return t(config.Text, props);
  };

  if (!notificationConfigs) return <Spin />;

  return (
    <Card>
      <List
        className="notificationsList"
        size="small"
        style={{
          maxHeight: "60vh",
          overflowY: "auto"
        }}
        loading={NotificationComponentStore.isLoading}
        header={
          NotificationComponentStore.notifications?.length > 0 && (
            <List.Item>
              <List.Item.Meta
                title={
                  <div className="notification">
                    <Checkbox
                      indeterminate={
                        selectedItems.length > 0 &&
                        selectedItems.length < NotificationComponentStore.notifications.length
                      }
                      onChange={handleSelectAllChange}
                      checked={selectAll}
                    />

                    {selectedItems.length > 0 ? (
                      <Popconfirm
                        title={t("notifications.delete.areYouSure")}
                        okText={t("notifications.delete.yes")}
                        cancelText={t("notifications.delete.no")}
                        onConfirm={async () => {
                          const isDeleteted = await NotificationComponentStore.deleteNotifications(selectedItems);
                          isDeleteted && setSelectedItems([]);
                        }}
                      >
                        <Button
                          size="small"
                          style={{ width: "20px", height: "20px", marginLeft: "17px" }}
                          icon={<DeleteOutlined />}
                        />
                      </Popconfirm>
                    ) : (
                      <span style={{ marginLeft: "17px", fontSize: "14px", fontWeight: "normal" }}>
                        {t("notifications.SelectAll")}
                      </span>
                    )}
                  </div>
                }
              ></List.Item.Meta>
            </List.Item>
          )
        }
        dataSource={NotificationComponentStore.notifications}
        renderItem={item => {
          return (
            <List.Item
              size="small"
              key={item.id}
              actions={[
                <Tooltip
                  title={moment(item.createdDateTime.split(".")[0].replace("T", " ")).format("YYYY-MM-DD HH:mm:ss")}
                >
                  <span>{moment(item.createdDateTime).fromNow()}</span>
                </Tooltip>
              ]}
            >
              <List.Item.Meta
                onClick={() => {
                  NotificationComponentStore.markAsRead([item.id]);
                }}
                avatar={<Checkbox checked={selectedItems.includes(item.id)} onChange={handleItemChange(item.id)} />}
                title={
                  <Flex>
                    <div
                      style={{ fontWeight: item.payload.IsRead ? "100" : "500" }}
                      dangerouslySetInnerHTML={{ __html: getText(item) }}
                    />
                    {item.payload.RedirectPath && item.payload.RedirectValue && item.payload.RedirectAssetType && (
                      <Link
                        style={{
                          fontWeight: item.payload.IsRead ? "100" : "500",
                          marginLeft: "5px"
                        }}
                        to={`/${item.payload.ProjectName}/${item.payload.RedirectAssetType}?query=%7B"id":"8a89aaab-0123-4456-b89a-b192b8217728","type":"group","children1":[%7B"type":"rule","id":"8a88a889-4567-489a-bcde-f192b8227f4b","properties":%7B"fieldSrc":"field","field":"${item.payload.RedirectPath}","operator":"equal","value":["${item.payload.RedirectValue}"],"valueSrc":["value"],"valueType":["text"]%7D%7D]%7D`}
                      >
                        {item.payload.RedirectAssetTypeName}
                      </Link>
                    )}
                  </Flex>
                }
              />
            </List.Item>
          );
        }}
      />

      <Modal
        title={t("notifications.notificationsSettings")}
        open={NotificationComponentStore.settingsModalVisible}
        onOk={NotificationComponentStore.saveSettings}
        maskClosable={false}
        style={{ minWidth: "60%" }}
        onCancel={() => (NotificationComponentStore.settingsModalVisible = false)}
        okText={t("notifications.okText")}
        cancelText={t("notifications.cancelText")}
      >
        <Spin spinning={NotificationComponentStore.isLoading}>
          <Tabs
            defaultActiveKey={head(keys(NotificationComponentStore.configs))}
            items={[
              ...keys(NotificationComponentStore.configs).map(parentKey => ({
                label: notificationsSettings()[parentKey],
                key: parentKey,
                children: (
                  <Table
                    pagination={false}
                    columns={[
                      { title: t("notifications.name"), dataIndex: "name", key: "name" },
                      {
                        title: t("notifications.email"),
                        dataIndex: "email",
                        key: "email",
                        render: (value, record) => {
                          return (
                            <Switch
                              checked={record.email}
                              onChange={() => {
                                NotificationComponentStore.updateSettingCheckbox(
                                  parentKey,
                                  record.key,
                                  "email",
                                  !value
                                );
                                updateNotificationValue(parentKey, value, record.key, "email");
                              }}
                            />
                          );
                        }
                      },
                      {
                        title: "Webapp",
                        dataIndex: "webapp",
                        key: "webapp",
                        render: (value, record) => (
                          <Switch
                            disabled={record.webappDisabled}
                            checked={record.webapp}
                            onChange={() => {
                              NotificationComponentStore.updateSettingCheckbox(parentKey, record.key, "webapp", !value);
                              updateNotificationValue(parentKey, value, record.key, "webapp");
                            }}
                          />
                        )
                      }
                    ]}
                    dataSource={notificationLIst?.[parentKey]}
                  />
                )
              })),
              {
                label: t("notifications.weeklySummary"),
                key: "WeeklySummaryTab",
                children: <NotificationWeeklyReportSettingsComponent />
              }
            ]}
          />
        </Spin>
      </Modal>
    </Card>
  );
};

export default withTranslation()(observer(NotificationComponent));
