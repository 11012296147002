import React from "react";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Layout } from "antd";

import BaseLayoutHeader from "./templates/BaseLayoutHeader";
import SearchFiltersTags from "./SearchFiltersTags";

import BaseLayoutStore from "./BaseLayoutStore";
import SearchComponentStore from "../../Common/Search/SearchComponentStore";

import * as _ from "lodash";
import * as queryString from "query-string";
import packageJson from "../../../package.json";
import BackendService from "../../Common/BackendService";
import BaseLayoutHeaderMobile from "./BaseLayoutHeaderMobile";
import BaseLayoutContentMobile from "./BaseLayoutContentMobile";
import CommentStore from "../../WorflowRiskControl/Comments/CommentStore";
import QueryBuilderStore from "../../components/queryBuilder/QueryBuilderStore";
import { themeConfig } from "./config";
import ViewGeneratorStore from "../../ViewGenerator/ViewGeneratorStore";

class BaseLayout extends React.Component {
  graphQlApi = new BackendService(`graphql`);
  children = this.props.children;
  currentTheme = themeConfig().currentTheme;

  state = {
    collapsed: false,
    visibleSearchDrawer: false,
    urlParsed: false,
    isDarkMode: false
  };

  componentDidMount() {
    BaseLayoutStore.init();

    if (this.currentTheme === "dark") this.setDarkTheme();

    const path = this.props.path ? this.props.path[0] : "";
    if (path.includes("/documents") || path.includes("/program") || path.includes("/comments")) {
      const symbol =
        (path.includes("/documents") && "document") ||
        (path.includes("/program") && "task") ||
        (path.includes("/comments") && "comment");

      this.graphQlApi
        .get(
          `?query={assetsDefinitionBySymbol(symbol: "${symbol}"){id,fields{symbol,value,type,dbTableLocation,label}}}`
        )
        .then(r => {
          const fields = r.data.assetsDefinitionBySymbol
            ? path.includes("/comments")
              ? [
                  ...r.data.assetsDefinitionBySymbol.fields,
                  {
                    symbol: "CreateByUserId",
                    type: "jarray",
                    values:
                      CommentStore.creators.length > 0 &&
                      CommentStore.creators.map(creator => {
                        return { value: creator.id, title: creator.username };
                      }),
                    dbTableLocation: "CreateByUserId",
                    label: "Created by"
                  }
                ]
              : r.data.assetsDefinitionBySymbol.fields
            : [];

          if (!_.isEmpty(window.location.search)) {
            if (!this.props.location.search.includes("?view=true")) {
              const queryParams = queryString.parse(window.location.search);
              let jqlUrlQuery = JSON.parse(decodeURIComponent(queryParams.query));

              if (fields && jqlUrlQuery) {
                SearchComponentStore.queryBuilderJson = jqlUrlQuery;
                SearchComponentStore.queryBuilderString = QueryBuilderStore.getQueryString(fields, jqlUrlQuery);
              }

              this.setState({ jqlUrlQuery, urlParsed: true });
            }
          } else if (
            (path.includes("/documents") || path.includes("/subcontractors" || path.includes("/comments"))) &&
            BaseLayoutStore.searchComponentJqlQuery()
          ) {
            const projectName = this.props.match.params.projectName;

            window.location.href = `${path.replace(":projectName?", projectName)}?query=${encodeURIComponent(
              JSON.stringify(BaseLayoutStore.searchComponentJqlQuery())
            )}`;
            this.setState({ urlParsed: true });
          } else {
            if (path.includes("/program")) {
              BaseLayoutStore.tasksLoadDefault();
            }
            this.setState({ urlParsed: true });
          }
        });
    }
  }

  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  toggleShowSearchDrawer = () => {
    this.setState({ visibleSearchDrawer: !this.state.visibleSearchDrawer });
    if (ViewGeneratorStore.advanceSearchDrawer.isVisible === true)
      ViewGeneratorStore.advanceSearchDrawer = { ...ViewGeneratorStore.advanceSearchDrawer, isVisible: false };
    else ViewGeneratorStore.advanceSearchDrawer = { ...ViewGeneratorStore.advanceSearchDrawer, isVisible: true };
  };

  cleanSession = () => {
    localStorage.clear();
    window.location.reload();
  };

  setDarkTheme() {
    this.setState({ isDarkMode: true });
    localStorage.setItem(`theme`, `dark`);
  }

  render() {
    const appConfig = JSON.parse(localStorage.getItem("appConfig"));

    const currentPlatform = localStorage.getItem("platform");

    if (currentPlatform === "mobile") {
      return (
        <Layout
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100vh"
          }}
        >
          <BaseLayoutHeaderMobile />
          <BaseLayoutContentMobile>{this.props.children}</BaseLayoutContentMobile>
        </Layout>
      );
    }

    return (
      <Layout style={{ position: "relative", minHeight: "100vh" }}>
        <BaseLayoutHeader
          urlParsed={this.state.urlParsed}
          user={BaseLayoutStore.user}
          toggleShowSearchDrawer={this.toggleShowSearchDrawer}
          history={this.props.history}
          urlQuery={this.state.jqlUrlQuery}
          pageTitle={this.props.pageTitle}
        />

        <SearchFiltersTags
          toggleShowSearchDrawer={this.toggleShowSearchDrawer}
          history={this.props.history}
          match={this.props.match}
          t={this.props.t}
        />

        <Layout.Content style={{ marginTop: "80px", marginLeft: 20, marginRight: 20, marginBottom: 0 }}>
          {React.cloneElement(this.props.children, {
            showDrawer: this.state.visibleSearchDrawer,
            toggleShowSearchDrawer: this.toggleShowSearchDrawer
          })}
        </Layout.Content>

        <Layout.Footer
          style={{
            textAlign: "center",
            bottom: 0,
            width: "100%",
            paddingBottom: 5,
            paddingRight: 25
          }}
        >
          <small style={{ float: "right" }}>
            {this.props.t("version")}: {packageJson.version}
          </small>
          {currentPlatform !== "mobile" && <small>{appConfig.footer.text}</small>}
        </Layout.Footer>
      </Layout>
    );
  }
}

export default withTranslation()(withRouter(observer(BaseLayout)));
