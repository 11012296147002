import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useRouteMatch, Route, useParams } from "react-router-dom";
import DocumentTitle from "react-document-title";
import { withTranslation } from "react-i18next";
import { observer } from "mobx-react";

import ConfigStore from "./Common/ConfigStore";
import GraphQlService from "./Common/GraphQlService";
import UserStore from "./Common/Users/UserStore";
// I18N
import "moment/locale/es";
import "moment/locale/en-gb";
import { Input, Modal, Spin, message } from "antd";
import { logout } from "./Common/Users/Logout";
import { drop, filter, forEach, find, includes, isEmpty, isNil } from "lodash";
import { toJS } from "mobx";
import ViewGeneratorStore from "./ViewGenerator/ViewGeneratorStore";
import useUserPermissions from "./Settings/hooks/useUserPermissions";
const AppRoute = ({ component: Component, layout: Layout, title: Title, path: Path, exact, ...rest }) => {
  const [title, setTitle] = useState(undefined);

  useEffect(() => {
    if (!ViewGeneratorStore.title) setTitle(Title);
    else setTitle(ViewGeneratorStore.title);
  }, [ViewGeneratorStore.title, Layout]);

  return <AppRouteResult layout={Layout} title={title} path={Path} component={Component} {...rest} />;
};

const AppRouteResult = ({ component: Component, layout: Layout, title: Title, path: Path, t, ...props }) => {
  const [isLoading, setIsLoading] = useState(true);

  const [changePassword, setChangePassword] = useState({ isVisible: false });

  const history = useHistory();
  const routeMatch = useRouteMatch();
  const graphQlService = new GraphQlService();

  const permissions = useUserPermissions();

  useEffect(() => {
    if (!localStorage.getItem(`jwtToken`) || window.location.href.includes(`utils/`)) setIsLoading(false);
    else loadUser();
  }, [routeMatch]);

  useEffect(() => {
    UserStore.loadUserAccess().then(() => {
      checkPermissionToPath();
    });
  }, [permissions, Path]);

  // useEffect(() => {
  //   if (UserStore.user && UserStore.user.id) {
  //     window.pendo.initialize({
  //       visitor: { id: UserStore.user.id },
  //       account: { id: "ACCOUNT-UNIQUE-ID" }
  //     });
  //   }
  // }, [UserStore.user]);

  const loadUser = async () => {
    var projectName = routeMatch.params?.projectName;

    if (!localStorage.getItem("projectName") || !localStorage.getItem("projectId")) {
      await loadProject();
      if (!localStorage.getItem("tenantId")) await loadTenant();
    }

    if (routeMatch.path.includes(`:projectName?`)) {
      if (
        (!localStorage.getItem("projectName") || !localStorage.getItem("projectId")) &&
        (!projectName || projectName === "undefined" || projectName == "null")
      )
        await loadProject();

      if (!localStorage.getItem("tenantId")) await loadTenant();

      if (
        !projectName ||
        projectName === "undefined" ||
        projectName == "null" ||
        projectName !== localStorage.getItem("projectName")
      ) {
        var newUrl = newProjectUrl(props);
        history.push(newUrl);
        return;
      }

      if ((localStorage.getItem(`projectName`) || "").toLowerCase() !== (projectName || "").toLowerCase()) {
        const getProjectId = await graphQlService.get(`{ projectByName(name: "${projectName}") { id tenantId } }`);
        if (getProjectId.errors || !getProjectId.data.projectByName) return logout(history);

        const switchProject = await graphQlService.post(
          `mutation switch($data: Guid, $tenantId: Guid) { projectSwitch(projectId: $data, tenantId: $tenantId) }`,
          {
            data: getProjectId.data.projectByName.id,
            tenantId: getProjectId.data.projectByName.tenantId
          }
        );
        var json = JSON.parse(switchProject.data.projectSwitch);
        localStorage.setItem(`jwtToken`, json.jwt);

        await loadProject();
        await loadTenant();

        return history.push(`/`);
      }

      const user = await graphQlService.get(`{ currentUser { id email otpSet } }`);

      if (!user || user.errors || !user.data || !user.data.currentUser) {
        return history.push(`/utils/login`);
      }
      if (user) {
        localStorage.setItem("email", user.data.currentUser.email);
        localStorage.setItem("id", user.data.currentUser.id);
        if (user.data.currentUser.otpSet) setChangePassword(() => ({ isVisible: true }));
      }
      await UserStore.loadUserAccess();
    }

    setIsLoading(false);
  };

  const newProjectUrl = props => {
    if (props.match.params.projectName && props.match.params.projectName === localStorage.getItem(`projectName`)) {
      return `/${localStorage.getItem(`projectName`)}/${drop(props.match.url.split("/"), 2).join("/")}${
        props.location.search
      }${props.location.hash}`;
    }
    return `/${localStorage.getItem(`projectName`)}${props.match.url}${props.location.search}${props.location.hash}`;
  };

  const loadProject = async () => {
    var project = await graphQlService.get(`{ project { value id menu variables }}`);
    if (project.errors) return logout(history);
    ConfigStore.currentMenu = project.data.project.menu;
    await ConfigStore.getCurrentMenu(project.data.project.menu, project.data.project.id);
    localStorage.setItem(`projectMenu`, project.data.project.menu);
    localStorage.setItem(`projectId`, project.data.project.id);
    localStorage.setItem(`projectName`, project.data.project.value);
    localStorage.setItem(`variables`, project.data.project.variables);
  };

  const loadTenant = async () => {
    var tenant = await graphQlService.get(`{ tenant { rootId plan headerLogo }}`);
    if (tenant.errors) return logout(history);

    localStorage.setItem(`tenantId`, tenant.data.tenant.rootId);
    localStorage.setItem(`plan`, tenant.data.tenant.plan);

    let appConfig = JSON.parse(localStorage.getItem(`appConfig`));
    appConfig.header.path = ConfigStore.backendUrlHost + "/" + tenant.headerLogo;
    localStorage.setItem(`appConfig`, JSON.stringify(appConfig));
  };

  const checkPermissionToPath = useCallback(() => {
    if (Array.isArray(permissions) && permissions.length > 0) {
      const redirectPath = `/${localStorage.getItem("projectName")}/mypriorities`;

      if (isNil(Path)) {
        const perm =
          find(permissions, perm => perm?.key === ViewGeneratorStore.assetDefinition.id)?.permission === "view";

        if (!perm) return (window.location.pathname = redirectPath);
      }

      const automatePermission = find(permissions, perm => perm?.key === "automate")?.permission === "view";
      const collectPermission = find(permissions, perm => perm?.key === "collect")?.permission === "view";
      const organizationMgmtPermission =
        find(permissions, perm => perm?.key === "organizationMgmt")?.permission === "view";
      const projectSettingsPermission =
        find(permissions, perm => perm?.key === "projectSettings")?.permission === "view";

      const isPathAutomate = Array.isArray(Path) ? includes(Path[0], "/automate") : includes(Path, "/automate");
      const isPathCollect = Array.isArray(Path) ? includes(Path[0], "/collect") : includes(Path, "/collect");

      const isPathOrganizationMgmt = Array.isArray(Path)
        ? includes(Path[0], `/:projectName?/settings`)
        : includes(Path, `/:projectName?/settings`);

      const isPathProjectSettings = Array.isArray(Path)
        ? includes(Path[0], `/utils/project/:subpage?/:tab?`)
        : includes(Path, `/utils/project/:subpage?/:tab?`);

      if (isPathAutomate && !automatePermission) return (window.location.pathname = redirectPath);
      if (isPathCollect && !collectPermission) return (window.location.pathname = redirectPath);
      if (isPathOrganizationMgmt && !organizationMgmtPermission) return (window.location.pathname = redirectPath);
      if (isPathProjectSettings && !projectSettingsPermission) return (window.location.pathname = redirectPath);
    }
  }, [Path, permissions, ViewGeneratorStore.assetDefinition]);

  if (isLoading)
    return (
      <div style={{ marginTop: "20%" }}>
        <div style={{ textAlign: "center" }}>
          <img
            src={"./Beawre_login_logo.png"}
            alt="Beawre"
            style={{ height: "42px", marginBottom: 30, marginTop: 40 }}
          />
        </div>
        <Spin spinning={true} size="large">
          <div style={{ height: 50, width: 50 }}></div>
        </Spin>
      </div>
    );

  return (
    <div>
      <DocumentTitle title={t(Title)}>
        <Layout pageTitle={Title} path={Path} {...props}>
          <Component {...props} />
        </Layout>
      </DocumentTitle>

      <Modal
        title={"Set new password"}
        open={changePassword.isVisible}
        destroyOnClose
        closable={false}
        maskClosable={false}
        style={{ width: "90%" }}
        onOk={() => {
          setChangePassword(c => ({ ...c, isLoading: true }));
          graphQlService
            .post(`mutation post($data: ChangePasswordCommand) { userChangePassword(data: $data) }`, {
              data: { password: changePassword.password }
            })
            .then(r => {
              if (r.errors) {
                graphQlService.displayErrors(r.errors);
              } else message.success(`Password has been changed!`);
              setChangePassword(() => ({ isVisible: r.errors ? true : false, isLoading: false }));
            });
        }}
        confirmLoading={changePassword.isLoading}
      >
        <h3 style={{ textAlign: "center" }}>
          You have logged in using your One Time Password. <br />
          Please change it before continue.
        </h3>
        <Input.Password
          value={changePassword.password}
          onChange={e => setChangePassword(c => ({ ...c, password: e.target.value }))}
          placeholder={"Password"}
          style={{ width: "100%" }}
        />
      </Modal>
    </div>
  );
};

export default withTranslation()(observer(AppRoute));
