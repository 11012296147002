import React, { useEffect, useState, useRef } from "react";
import { Form, Input } from "antd";
import { withTranslation } from "react-i18next";
import InputMask from "react-input-mask";

const FormInput = props => {
  const [validationError, setValidationError] = useState(undefined);
  const [displayValue, setDisplayValue] = useState("");
  const [rawValue, setRawValue] = useState("");
  const [forceRender, setForceRender] = useState(false);

  const inputRef = useRef(null);
  const hasMultipleMasks = props.mask?.includes(",");
  const maskList = hasMultipleMasks ? props.mask.split(",").map(m => m.trim()) : [props.mask];
  const [mask, setMask] = useState(maskList[0]);

  const parseMasks = maskString => {
    return maskString.split(",").map(m => m.trim());
  };

  const chooseMask = (value, masks) => {
    const valueLength = value.length;
    let selectedMask = masks[0];

    masks.forEach(m => {
      const maskLength = m.replace(/\W/g, "").length;
      if (valueLength <= maskLength) {
        selectedMask = m;
      }
    });

    return selectedMask;
  };
  const getAllowedCharsRegex = mask => {
    const allowsLetters = /a/.test(mask);

    if (!allowsLetters) {
      return /[^0-9hm]/g;
    }

    let allowedChars = mask.replace(/9/g, "\\d").replace(/a/g, "[a-zA-Z]");

    return new RegExp(`[^${allowedChars}]`, "g");
  };
  const handleChange = e => {
    const input = e.target;
    const valueWithMask = input.value;
    let cursorPosition = input.selectionStart;
    // const valueWithoutMask = valueWithMask.replace(/[^0-9hm]/g, "");
    const allowedCharsRegex = getAllowedCharsRegex(mask);

    const valueWithoutMask = valueWithMask.replace(allowedCharsRegex, "");

    setDisplayValue(valueWithMask);
    setRawValue(valueWithoutMask);

    const masks = parseMasks(props.mask);

    const selectedMask = chooseMask(valueWithoutMask, masks.length > 1 ? masks : [props.mask]);

    if (selectedMask !== mask) {
      setMask(selectedMask);

      setTimeout(() => {
        const newCursorPosition = findLastValidCursorPosition(valueWithMask);
        input.setSelectionRange(newCursorPosition, newCursorPosition);
      }, 0);
    } else {
      setTimeout(() => {
        input.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
      }, 0);
    }
  };
  const findLastValidCursorPosition = maskedValue => {
    for (let i = maskedValue.length - 1; i >= 0; i--) {
      if (maskedValue[i].match(/[0-9a-zA-Z]/)) {
        return i + 2;
      }
    }
    return maskedValue.length;
  };

  const handleBlur = () => {
    if (props.updateValue) {
      props.updateValue(props.id, rawValue);
    }
  };

  useEffect(() => {
    setDisplayValue(props.storeValue !== undefined ? props.storeValue : props.value || "");
    validate();
    if (props.storeValue && props.mask) {
      const masks = parseMasks(props.mask);
      const selectedMask = chooseMask(props.storeValue, masks);
      setMask(selectedMask);
    }
  }, []);

  useEffect(() => {
    if (props.storeValue === undefined && props.value !== undefined && props.updateValue)
      props.updateValue(props.id, props.value);
    validate();
  }, [props.value]);

  useEffect(() => {
    validate();
  }, [displayValue]);

  const validate = () => {
    if (!props.identifier || !props.isSubmission) return;
    setValidationError(undefined);
    if (!displayValue || displayValue === "") {
      setValidationError({ status: "error", text: props.t("collect.validations.missingValue") });
      if (props.setValidityStatus) props.setValidityStatus(props.id, false);
    } else {
      setValidationError({ status: undefined, text: "" });
      if (props.setValidityStatus) props.setValidityStatus(props.id, true);
    }
  };

  const validateMask = (mask, inputMode) => {
    const validMaskChars = inputMode ? /^[9a-zA-Z\*\+\-\/\(\)\.\:\s]*$/ : /^[9a\*\+\-\/\(\)\.\:\s]*$/;

    if (!mask || !validMaskChars.test(mask)) {
      return null;
    }
    return mask;
  };

  const isNumericMask = mask => {
    const regex = /^[\d\*\+\-\/\(\)\.\:\s]*$/;
    return regex.test(mask);
  };

  const validatedMask = validateMask(mask, props.inputMode);

  return (
    <Form.Item
      name={props.label}
      label={
        <span>
          {props.label} {props.isRequired && <span style={{ color: "red", marginLeft: 2 }}>(*)</span>}
        </span>
      }
      disabled={props.disabled}
      style={{ margin: "15px 0px" }}
      validateStatus={validationError?.status}
      hasFeedback
      help={validationError?.text}
    >
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <InputMask
          ref={inputRef}
          placeholder={props.placeholder}
          key={props.id}
          mask={validatedMask}
          value={displayValue}
          defaultValue={props.value}
          maskChar={null}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={props.disabled}
          inputMode={props.inputMode || (!props.inputMode && isNumericMask(mask)) ? "numeric" : "text"}
        >
          {inputProps => (
            <Input {...inputProps} style={{ marginRight: 20 }} disableUnderline disabled={props.disabled} />
          )}
        </InputMask>
      </div>
    </Form.Item>
  );
};

export default withTranslation()(FormInput);
