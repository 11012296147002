import { attempt, isError } from "lodash";

export const getPartText = (regex, value) => {
  return value.match(regex)[1];
};
export const isValidJSON = text => {
  const result = attempt(JSON.parse, text);
  return !isError(result);
};
export const convertToISOFormat = dateStr => {
  return dateStr.replace(" ", "T") + "Z";
};

export const getRandomColor = () => {
  const randomChannel = () => Math.floor(Math.random() * (200 - 50) + 50);
  return `rgb(${randomChannel()}, ${randomChannel()}, ${randomChannel()})`;
};
