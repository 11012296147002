import React, { useEffect, useState } from "react";
import { DatePicker, Form } from "antd";
import { Form as FormMobile, Input as InputMobile } from "antd-mobile";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";

dayjs.extend(weekday);
dayjs.extend(localeData);

const currentPlatform = localStorage.getItem("platform");

const FormDateTime = props => {
  const currentPlatform = localStorage.getItem("platform");

  const [value, setValue] = useState();

  useEffect(() => {
    var newValue = dayjs();
    if (props.isBuilder) {
      if (props.predefinedDate === "Tomorrow") newValue = dayjs().add(1, "days");
      else if (props.predefinedDate === "Yesterday") newValue = dayjs().add(-1, "days");

      setValue(undefined);
    }
    if (props.isSubmission && props.storeValue) {
      setValue(dayjs(props.storeValue));
      return;
    }

    if (props.isSubmission && !props.storeValue) {
      if (["Today", "Tomorrow", "Yesterday"].includes(props.value) || !props.value) {
        if (props.predefinedDate === "Today") newValue = dayjs();
        else if (props.predefinedDate === "Tomorrow") newValue = dayjs().add(1, "day");
        else if (props.predefinedDate === "Yesterday") newValue = dayjs().add(-1, "day");

        setValue(newValue);
        if (props.updateValue) props.updateValue(props.id, newValue.toDate());
        return;
      } else {
        setValue(dayjs(props.value));
        return;
      }
    }
    setValue(newValue);
  }, []);

  if (currentPlatform === "mobile") {
    return (
      <FormMobile.Item
        label={
          <>
            {props.label} {props.isRequired && <span style={{ color: "red", marginLeft: 2 }}>(*)</span>}
          </>
        }
        disabled={props.disabled}
      >
        <DatePicker
          format={props.dateFormat || "DD/MM/YYYY"}
          allowClear={false}
          disabled={props.disabled}
          value={value}
          onChange={e => {
            setValue(e);
            props.updateValue(props.id, e.toDate());
          }}
          style={{ display: "flex", justfifyContent: "flex-end" }}
        />
      </FormMobile.Item>
    );
  }

  return (
    <Form.Item
      label={
        <>
          {props.label} {props.isRequired && <span style={{ color: "red", marginLeft: 2 }}>(*)</span>}
        </>
      }
      style={currentPlatform === "mobile" ? { margin: "15px 10px 15px 20px" } : { margin: "15px 10px 15px 0px" }}
      disabled={props.disabled}
    >
      <DatePicker
        format={props.dateFormat || "DD/MM/YYYY"}
        allowClear={false}
        disabled={props.disabled}
        value={value}
        onChange={e => {
          setValue(e);

          console.log('set datetime', e, e.toDate())
          props.updateValue(props.id, e.toDate());
        }}
        // value={moment("10/10/2024", "DD/MM/YYYY")}
        style={
          currentPlatform === "mobile"
            ? { display: "flex", justfifyContent: "flex-end", marginLeft: 40 }
            : { marginLeft: 0, width: "100%" }
        }
      />
    </Form.Item>
  );
};

export default FormDateTime;
