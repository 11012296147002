import React, { useEffect, useRef, useState } from "react";

import {
  Space,
  Flex,
  Button,
  Col,
  Drawer,
  Input,
  Row,
  Select,
  Table,
  Tooltip,
  Switch,
  InputNumber,
  Form,
  message,
  Upload,
  Modal,
  Divider,
  Empty,
  Typography,
  Spin
} from "antd";
import { CameraOutlined, EyeOutlined, TableOutlined, UploadOutlined, SettingOutlined } from "@ant-design/icons";

import { orderBy, filter, keys, sumBy, isEmpty, clone } from "lodash";
import { withTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import FormBuilderStore from "../../MyForms/FormBuilderStore";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import "./style.css";
import { toJS } from "mobx";
import GraphQlService from "../../../Common/GraphQlService";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { Toast } from "antd-mobile";
import FormSubmissionStore from "../../Submissions/FormSubmissionStore";
import useLanguageEffect from "../../hooks/useLanguageEffect";
import { t } from "i18next";
import { isValidJSON } from "../../../utils";
const CollectFormTable = props => {
  const [showTableDrawer, setShowTableDrawer] = useState(false);

  return (
    <>
      <label>{props.label}</label>
      <br />
      {props.isSidebarElement && (
        <div style={{ textAlign: "center" }}>
          <TableOutlined />
        </div>
      )}
      {(props.isBuilder || props.isSubmission) && (
        <>
          {props.tablePlacement === "drawer" && (
            <CollectFormTableForm {...props} setShowTableDrawer={setShowTableDrawer} />
          )}
          {props.tablePlacement === "form" && <CollectFormTableComponent {...props} />}
        </>
      )}

      {showTableDrawer && (
        <Drawer open={true} width={"90%"} onClose={() => setShowTableDrawer(false)}>
          <CollectFormTableComponent {...props} />
        </Drawer>
      )}
    </>
  );
};

const CollectFormTableForm = observer(props => {
  const [newEntry, setNewEntry] = useState({ rowId: uuidv4() });
  const [isLoading, setIsLoading] = useState(false);

  const addToTable = () => {
    if (keys(newEntry).length === 1) return;

    var tableValues = [];
    if (Object.prototype.toString.call(props.value) === "[object Array]") tableValues = props.value;
    else tableValues = JSON.parse(props.value || "[]");

    tableValues.push(newEntry);
    if (props.updateValue)
      props.updateValue(props.id, JSON.stringify(tableValues), {
        headers: props.items.map(i => ({ key: i.key, title: i.value, dataIndex: i.id }))
      });
    message.success(props.t("collect.entryAdded"));
    setNewEntry({ rowId: uuidv4() });
  };

  const custom = async (e, item) => {
    setIsLoading(true);
    const graphQlService = new GraphQlService();
    graphQlService
      .post(
        `mutation mutate($file: String, $filename: String, $id: Guid){ formSubmissionUploadFile(file: $file, filename: $filename, id: $id) }`,
        {
          file: await toBase64(e.file),
          filename: e.file.name,
          id: props.submissionId
        }
      )
      .then(r => {
        e.onSuccess(r.data.formSubmissionUploadFile);
        message.success(`${e.file.name} ${props.t("collect.fileUploadedSuccess")}`);

        setNewEntry(pv => ({ ...pv, [item.id]: r.data.formSubmissionUploadFile }));
        setIsLoading(false);
      })
      .error(e => {
        console.error(e);
        setIsLoading(false);
      });
  };

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const cameraClosed = () => {
    setTimeout(() => {
      FormSubmissionStore.cameraIsOpen = false;
    }, 2000);
  };

  const selectImageFromGallery = async (type, item) => {
    FormSubmissionStore.cameraIsOpen = true;

    try {
      let image = null;

      const permissionCheck = await Camera.checkPermissions();
      if (type === "camera") {
        if (permissionCheck.camera == "denied" || permissionCheck.camera == "prompt")
          await Camera.requestPermissions({ permissions: ["camera"] });
        image = await Camera.getPhoto({
          quality: 100,
          allowEditing: false,
          resultType: CameraResultType.Base64,
          source: CameraSource.Camera
        });
      } else if (type == "photos") {
        if (permissionCheck.photos == "denied" || permissionCheck.photos == "prompt")
          await Camera.requestPermissions({ permissions: ["photos"] });

        image = await Camera.getPhoto({
          quality: 100,
          allowEditing: false,
          resultType: CameraResultType.Base64,
          source: CameraSource.Photos
        });
      }

      if (image) {
        const selectedImage = "data:image/jpeg;base64," + image.base64String;

        setIsLoading(true);
        const graphQlService = new GraphQlService();
        const response = await graphQlService.post(
          `mutation mutate($file: String, $filename: String, $id: Guid) {
                    formSubmissionUploadFile(file: $file, filename: $filename, id: $id)
                  }`,
          {
            file: selectedImage,
            filename: `image_${Date.now()}.jpg`,
            id: props.submissionId
          }
        );

        setNewEntry(pv => ({ ...pv, [item.id]: response.data.formSubmissionUploadFile }));
        Toast.show({ icon: "success", content: props.t("collect.imageUploaded") });
        cameraClosed();
      } else {
        Toast.show({ icon: "fail", content: props.t("collect.noImageSelected") });
        cameraClosed();
      }
    } catch (error) {
      Toast.show({ icon: "fail", content: props.t("collect.imageUploadFailed") + JSON.stringify(error) });
      console.error("Error uploading image:", error);
      cameraClosed();
      setIsLoading(false);
    } finally {
      cameraClosed();
      setIsLoading(false);
    }
  };
  return (
    <>
      <Form name="basic" labelCol={{ span: 6 }} wrapperCol={{ span: 12 }}>
        {orderBy(props.items, x => x.order, "asc").map(item => {
          return (
            <Form.Item label={item.value}>
              {(!item.valueType || item.valueType?.includes("string")) &&
                (item?.enum && isValidJSON(item.enum) && item.enum.startsWith("[") && item.enum.endsWith("]") ? (
                  <Select
                    disabled={props.disabled}
                    size="small"
                    allowClear
                    value={newEntry[item.id]}
                    options={
                      !item?.enum
                        ? []
                        : JSON.parse(item?.enum)?.[0]?.label
                        ? JSON.parse(item?.enum)
                        : JSON.parse(item?.enum).map(en => ({
                            label: en,
                            value: en
                          }))
                    }
                    onChange={e => {
                      setNewEntry(pv => ({ ...pv, [item.id]: e }));
                    }}
                    showSearch
                    optionFilterProp="label"
                  />
                ) : (
                  <Input
                    size="small"
                    value={newEntry[item.id]}
                    disabled={props.disabled}
                    onChange={e => setNewEntry(pv => ({ ...pv, [item.id]: e.target.value }))}
                  />
                ))}
              {(item.valueType?.includes("int") || item.valueType?.includes("decimal")) && (
                <InputNumber
                  size="small"
                  value={newEntry[item.id]}
                  disabled={props.disabled}
                  onChange={e => setNewEntry(pv => ({ ...pv, [item.id]: e }))}
                  style={{ width: "100%" }}
                />
              )}
              {item.valueType === "c_photo" && (
                <div style={{ textAlign: "center" }}>
                  {!newEntry[item.id] && localStorage.getItem(`platform`) === "mobile" && (
                    <Button
                      size="small"
                      style={{ marginRight: 5 }}
                      icon={<CameraOutlined />}
                      onClick={() => selectImageFromGallery("camera", item)}
                      disabled={isLoading || props.disabled}
                    >
                      {props.t("collect.takePicture")}
                    </Button>
                  )}

                  {!newEntry[item.id] && (
                    <Upload
                      {...{
                        name: "file",
                        multiple: false,
                        accept: "image/*",
                        customRequest: o => custom(o, item),
                        disabled: props.disabled
                      }}
                    >
                      <Button htmlType="button" icon={<UploadOutlined />} disabled={props.disabled} size="small">
                        {props.t("collect.clicktoUpload")}
                      </Button>
                    </Upload>
                  )}

                  {newEntry[item.id] && (
                    <>
                      <Button
                        onClick={() => {
                          setNewEntry(pv => ({ ...pv, [item.id]: undefined }));
                        }}
                        loading={isLoading || props.disabled}
                      >
                        {isLoading ? props.t("documents.loading") : props.t("collect.removeUploadedFile")}
                      </Button>
                    </>
                  )}
                </div>
              )}
            </Form.Item>
          );
        })}

        <Divider />
        <div>
          <Button
            type="primary"
            onClick={() => addToTable()}
            size="small"
            style={{ marginRight: 30 }}
            disabled={isLoading || props.disabled}
          >
            {isLoading
              ? props.t("documents.loading")
              : props.submitFormButtonName
              ? props.submitFormButtonName
              : props.t("collect.addNewEntry")}
          </Button>

          <Button type="default" onClick={() => props.setShowTableDrawer(true)} size="small">
            {props.t("collect.showTable")}
          </Button>
        </div>
      </Form>
    </>
  );
});

const CollectFormTableComponent = observer(props => {
  const { i18n } = useTranslation();
  const [tableValues, setTableValues] = useState([]);
  const [previewFile, setPreviewFile] = useState();
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props.value) {
      let value = null;
      if (typeof props.value === "string") value = JSON.parse(props.value);
      else value = props.value;

      // var fileTypeIds = props.items.filter(x => x.valueType === "c_photo").map(x => x.id);

      // _.forEach(value, item => {
      //     _.keys(item).forEach(itemPropertyKey => {
      //         if (fileTypeIds.includes(itemPropertyKey) && !item[itemPropertyKey].includes('<a href='))
      //             item[itemPropertyKey] = `${localStorage.getItem(`host`)}/${item[itemPropertyKey]}`
      //     })
      // })

      setTableValues(value);
    }
  }, []);

  useEffect(() => {
    if (props.updateValue)
      props.updateValue(props.id, JSON.stringify(tableValues), {
        headers: props.items.map(i => ({ key: i.key, title: i.value, dataIndex: i.id }))
      });
  }, [tableValues]);
  const prepareColumn = () => {
    const preColumns = orderBy(props.items, x => x.order, "asc").map(i => ({
      key: i.key,
      title: i.value,
      dataIndex: i.id,
      editable: true, //i.valueType === "c_photo" ? false : true,
      valueType: i.valueType
    }));

    const deleteButton = {
      dataIndex: "",
      editable: false,
      key: "x",
      title: props.t("collect.action"),
      valueType: "delete"
    };
    const copyPreColumns = [...preColumns, deleteButton];

    setColumns(() =>
      copyPreColumns.map(col => {
        return {
          ...col,
          render: (text, record, index) => {
            if (col.valueType && col.valueType === "c_photo")
              return (
                <Button
                  type="default"
                  size="small"
                  onClick={() => {
                    let url = clone(text);
                    if (url.includes("file_") && !url.includes(`api/files`)) url = `api/files?path=` + text;
                    setPreviewFile(url);
                  }}
                >
                  <EyeOutlined />
                </Button>
              );
            if (col.valueType === "delete")
              return (
                <Button type="default" size="small" onClick={() => deleteElement(record)}>
                  {props.t("collect.delete")}
                </Button>
              );
            return text;
          },
          onCell: record => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            valueType: col.valueType,
            disabled: props.disabled,
            key: col.key,
            handleSave: (dataIndex, value) => {
              record[dataIndex] = value;
              setTableValues([...tableValues]);
            }
          })
        };
      })
    );
  };
  useLanguageEffect(prepareColumn, [tableValues]);

  const deleteElement = record => {
    setTableValues(actualTablesValue => {
      return filter(actualTablesValue, o => {
        return record.rowId !== o.rowId;
      });
    });
  };
  const currentPlatform = localStorage.getItem("platform");

  return (
    <>
      <Table
        components={{
          body: {
            cell: p => EditableCell({ ...p, t: props.t, submissionId: props.submissionId })
          }
        }}
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={props.t("collect.noDataEntry")} />
        }}
        rowKey={r => r.rowId}
        scroll={currentPlatform === "mobile" ? { x: 1000 } : {}}
        rowClassName={() => "editable-row"}
        size="small"
        dataSource={tableValues}
        columns={columns}
        footer={() => (
          <div style={{ textAlign: "center" }}>
            <Button
              type="default"
              disabled={props.disabled}
              onClick={() => setTableValues([...tableValues, { rowId: uuidv4() }])}
              size="small"
            >
              {props.t("collect.newRow")}
            </Button>
          </div>
        )}
        summary={() => {
          if (
            orderBy(props.items, x => x.order, "asc").filter(
              x => x.sumInColumn && (x.valueType?.includes("int") || x.valueType?.includes("decimal"))
            ) === 0
          )
            return <></>;
          return (
            <Table.Summary fixed>
              <Table.Summary.Row>
                {orderBy(props.items, x => x.order, "asc").map((i, index) => {
                  if (i.sumInColumn && (i.valueType?.includes("int") || i.valueType?.includes("decimal")))
                    return (
                      <Table.Summary.Cell key={`${props.id}_summery_${index}`} index={index}>
                        <b>{sumBy(tableValues, i.id)}</b>
                      </Table.Summary.Cell>
                    );

                  return <Table.Summary.Cell key={`${props.id}_summery_${index}`} index={index}></Table.Summary.Cell>;
                })}
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />

      {previewFile && (
        <Modal
          open={true}
          destroyOnClose
          closable
          onCancel={() => setPreviewFile(undefined)}
          title={props.t(`documents.drawer.viewFiles`)}
          footer={null}
        >
          <Divider />
          <img width="100%" height="100%" src={localStorage.getItem(`host`) + "/" + previewFile} />
        </Modal>
      )}
    </>
  );
});

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  valueType,
  record,
  disabled,
  fileUploadCustom,
  t,
  handleSave,
  submissionId,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const custom = async (e, item) => {
    setIsLoading(true);
    const graphQlService = new GraphQlService();
    graphQlService
      .post(
        `mutation mutate($file: String, $filename: String, $id: Guid){ formSubmissionUploadFile(file: $file, filename: $filename, id: $id) }`,
        {
          file: await toBase64(e.file),
          filename: e.file.name,
          id: submissionId
        }
      )
      .then(r => {
        e.onSuccess(r.data.formSubmissionUploadFile);
        message.success(`${e.file.name} ${t("collect.fileUploadedSuccess")}`);

        console.log("?", r.data.formSubmissionUploadFile);
        // setValue(r.data.formSubmissionUploadFile);
        // setTimeout(() => {
        save(r.data.formSubmissionUploadFile);
        setIsLoading(false);
        // }, 500);
      });
  };

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  useEffect(() => {
    if (record) setValue(record[dataIndex]);
  }, [editing]);

  const toggleEdit = () => {
    if (!editing) setValue(record[dataIndex]);
    setEditing(!editing);
  };

  const save = async (valueToSave = value) => {
    console.log("save", dataIndex, valueToSave);
    handleSave(dataIndex, valueToSave);
    setEditing(!editing);
  };

  let childNode = children;

  if (editable && !disabled) {
    if (editing) {
      childNode = (
        <Form.Item style={{ margin: 0 }}>
          {(!valueType || valueType?.includes("string")) && (
            <Input
              ref={inputRef}
              disabled={disabled}
              value={value}
              onPressEnter={() => save()}
              onBlur={() => save()}
              onChange={e => setValue(e.target.value)}
            />
          )}
          {valueType && (valueType?.includes("int") || valueType?.includes("decimal")) && (
            <InputNumber
              ref={inputRef}
              disabled={disabled}
              value={value}
              onPressEnter={() => save()}
              onBlur={() => save()}
              onChange={e => setValue(e)}
              style={{ width: "100%" }}
            />
          )}
          {valueType && valueType?.toLowerCase().includes("c_photo") && (
            <Spin spinning={isLoading}>
              <Upload
                {...{
                  name: "file",
                  multiple: false,
                  accept: "image/*",
                  customRequest: o => custom(o),
                  disabled: disabled
                }}
              >
                <Button htmlType="button" icon={<UploadOutlined />} size="small">
                  {t("collect.clicktoUpload")}
                </Button>
              </Upload>
            </Spin>
          )}
        </Form.Item>
      );
    } else {
      if (value && valueType?.toLowerCase().includes("c_photo")) return <td {...restProps}>{childNode}</td>;

      if (value)
        childNode = (
          <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
            {valueType?.toLowerCase().includes("c_photo") ? value?.filename : value}
          </div>
        );
      else
        childNode = (
          <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
            <a>{t("collect.setValue")}</a>
          </div>
        );
    }
  }

  return <td {...restProps}>{childNode}</td>;
};

export const CollectFormTableInputSettings = observer(props => {
  const [settingsEnum, setSettingsEnum] = useState(false);
  const inputStyle = { marginLeft: 5, width: "90%" };
  const [settingsEnumId, setSettingsEnumId] = useState(null);

  const handleOpenModal = recordId => {
    setSettingsEnumId(recordId);
    setSettingsEnum(true);
  };
  return (
    <>
      <Row gutter={4} style={{ marginTop: 5 }}>
        <Col span={6} style={{ textAlign: "right" }}>
          {props.t("Placement")}
        </Col>
        <Col span={18} style={{ textAlign: "center" }}>
          <Switch
            checkedChildren="Main form"
            unCheckedChildren="Drawer"
            defaultChecked
            style={{ width: "60%" }}
            checked={FormBuilderStore.modifyInputColumn.tablePlacement === "form"}
            onChange={e => props.setFieldValue("tablePlacement", e === true ? "form" : "drawer")}
          />
        </Col>
      </Row>
      {FormBuilderStore.modifyInputColumn.tablePlacement === "drawer" && (
        <>
          <Row gutter={4} style={{ marginTop: 5 }}>
            <Col span={6} style={{ textAlign: "right" }}>
              {props.t("Submit form button name")}
            </Col>
            <Col span={18} style={{ textAlign: "center" }}>
              <Input
                size="small"
                defaultValue={"Add"}
                style={inputStyle}
                value={FormBuilderStore.modifyInputColumn.submitFormButtonName}
                onChange={e => props.setFieldValue("submitFormButtonName", e.target.value)}
              />
            </Col>
          </Row>
        </>
      )}

      <Row gutter={4} style={{ marginTop: 5 }}>
        <Col span={6} style={{ textAlign: "right" }}>
          {props.t("collect.assetDefinitionField")}
        </Col>
        <Col span={18}>
          <Select
            size="small"
            style={inputStyle}
            allowClear
            options={orderBy(
              FormBuilderStore.assetDefinition?.fields?.map(x => ({ value: x.id, label: x.label })) || [],
              x => x.label,
              "asc"
            )}
            value={FormBuilderStore.modifyInputColumn.assetDefinitionFieldId}
            onChange={e => {
              props.setFieldValue("assetDefinitionFieldId", e);
              var field = FormBuilderStore.assetDefinition.fields.find(x => x.id === e);
              if (field && field.subFields)
                FormBuilderStore.modifyInputColumn.items = field.subFields.map(sf => ({
                  id: sf.id,
                  valueType: sf.type,
                  value: sf.label
                }));
              else FormBuilderStore.modifyInputColumn.items = [];
            }}
            showSearch
            optionFilterProp="label"
          ></Select>
        </Col>
      </Row>

      <div>
        {props.t("collect.headers")}
        <br />
        <Table
          pagination={{ hideOnSinglePage: true, pageSize: 30 }}
          dataSource={FormBuilderStore.modifyInputColumn.items}
          size="small"
          columns={[
            {
              align: "center",
              title: "Name",
              key: "name",
              render: (v, record) => {
                return (
                  <Input
                    size="small"
                    style={inputStyle}
                    value={record.value}
                    disabled
                    onChange={e => {
                      record.value = e.target.value;
                      FormBuilderStore.modifyInputColumn.items.find(x => x.id === record.id).value = e.target.value;
                      FormBuilderStore.modifyInputColumn.items = [...FormBuilderStore.modifyInputColumn.items];
                    }}
                  />
                );
              }
            },
            {
              align: "center",
              title: "Type",
              key: "value-type",
              render: (_, record) => {
                return (
                  <Select
                    size="small"
                    value={record.valueType}
                    defaultValue={"string"}
                    style={{ width: "100%" }}
                    disabled
                    options={[
                      { label: "Text", value: "s_string" },
                      { label: "Number", value: "s_int?" },
                      { label: "File", value: "c_File" }
                    ]}
                    onChange={e => {
                      record.valueType = e;
                      FormBuilderStore.modifyInputColumn.items.find(x => x.id === record.id).valueType = e;
                      FormBuilderStore.modifyInputColumn.items = [...FormBuilderStore.modifyInputColumn.items];
                    }}
                  />
                );
              }
            },
            {
              title: "Order",
              align: "center",
              key: "order",
              render: (v, record) => (
                <InputNumber
                  size="small"
                  style={{ textAlign: "center" }}
                  value={record.order}
                  onChange={e => {
                    record.order = e;
                    FormBuilderStore.modifyInputColumn.items.find(x => x.id === record.id).order = e;
                    FormBuilderStore.modifyInputColumn.items = [...FormBuilderStore.modifyInputColumn.items];
                  }}
                />
              )
            },
            {
              align: "center",
              title: "Settings",
              key: "settings",
              render: (v, record) => {
                return (
                  (!record.valueType ||
                    record.valueType === "string" ||
                    record.valueType === "s_string" ||
                    record.valueType === "s_int?") && (
                    <>
                      <SettingOutlined onClick={() => handleOpenModal(record.id)} />
                      <Modal
                        open={settingsEnum && settingsEnumId === record.id}
                        onClose={() => setSettingsEnum(false)}
                        onOk={() => setSettingsEnum(false)}
                        onCancel={() => setSettingsEnum(false)}
                        title="Settings"
                        footer={[
                          <Button key="submit" type="primary" onClick={() => setSettingsEnum(false)}>
                            OK
                          </Button>
                        ]}
                      >
                        <Flex vertical justify="space-around">
                          <Flex style={{ marginBottom: "20px", marginTop: "20px" }}>
                            <label htmlFor="list" style={{ flexGrow: 1 }}>
                              Subtype:
                            </label>
                            <Select
                              style={{ width: "80%" }}
                              id="list"
                              size="small"
                              value={record.list}
                              options={[
                                { label: "List (csv)", value: "csv" },
                                { label: "List (JSON)", value: "json" }
                              ]}
                              onChange={e => {
                                record.list = e;
                                FormBuilderStore.modifyInputColumn.items.find(x => x.id === record.id).list = e;
                                FormBuilderStore.modifyInputColumn.items = [
                                  ...FormBuilderStore.modifyInputColumn.items
                                ];
                              }}
                            />
                          </Flex>
                          <Flex>
                            <label htmlFor="enum" style={{ flexGrow: 1 }}>
                              Enum:
                            </label>
                            <Input
                              style={{ width: "80%" }}
                              id="enum"
                              size="small"
                              value={record.enum}
                              onChange={e => {
                                const newEnumValue = e.target.value;
                                const updatedItems = FormBuilderStore.modifyInputColumn.items.map(item =>
                                  item.id === record.id ? { ...item, enum: newEnumValue } : item
                                );
                                FormBuilderStore.modifyInputColumn.items = updatedItems;
                              }}
                            />
                          </Flex>
                        </Flex>
                      </Modal>
                    </>
                  )
                );
              }
            }
          ]}
        />
      </div>
    </>
  );
});
export default withTranslation()(observer(CollectFormTable));
