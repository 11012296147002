import React, { useEffect, useState, useRef } from "react";
import { Button, Form, Input, Flex, Space } from "antd";
import { withTranslation } from "react-i18next";
import SignatureCanvas from "react-signature-canvas";
const FormSignature = props => {
  const sigCanvas = useRef(null);
  const lastSignature = useRef(null);
  const containerRef = useRef(null);
  const [isEmpty, setIsEmpty] = useState(true);

  const checkIfEmpty = () => {
    saveSignature();
    setIsEmpty(sigCanvas.current.isEmpty());
  };

  const resizeCanvas = () => {
    const canvas = sigCanvas.current.getCanvas();
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = containerRef.current.offsetWidth * ratio;
    canvas.height = containerRef.current.offsetHeight * ratio;
    canvas.getContext("2d").scale(ratio, ratio);
  };

  useEffect(() => {
    resizeCanvas();
  }, []);

  const clearSignature = () => {
    sigCanvas.current.clear();
    setIsEmpty(true);
    props.updateValue(props.id, null);
    lastSignature.current = null;
  };

  const getSignature = () => {
    if (props.storeValue) {
      return props.storeValue;
    }
    return null;
  };

  useEffect(() => {
    const signatureData = getSignature();
    if (signatureData !== lastSignature.current) {
      sigCanvas.current?.fromDataURL(props.storeValue);

      props.updateValue(props.id, props.storeValue);
      lastSignature.current = props.storeValue;
    }
  }, [isEmpty]);

  const saveSignature = () => {
    if (!sigCanvas.current.isEmpty()) {
      const signatureData = sigCanvas.current.toDataURL("image/png");
      props.updateValue(props.id, signatureData);
    }
  };
  return (
    <Form.Item
      name={props.label}
      disabled={props.disabled}
      label={
        <span>
          {props.label} {props.isRequired && <span style={{ color: "red", marginLeft: 2 }}>(*)</span>}
        </span>
      }
      style={{ margin: "15px 0px" }}
      // validateStatus={validationError?.status}
      hasFeedback
      // help={validationError?.text}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Flex
          style={{
            border: "0.6px solid #999",
            borderRadius: "10px",
            width: "100%",
            height: "200px",
            backgroundColor: props.disabled ? "#0000000A" : "#fff"
          }}
          ref={containerRef}
        >
          <SignatureCanvas
            ref={sigCanvas}
            clearOnResize={false}
            penColor={props.disabled ? "transparent" : "black"}
            canvasProps={{
              border: "0.6px solid #999",
              borderRadius: "10px",
              className: "sigCanvas",
              style: {
                width: "100%",
                height: "100%",
                pointerEvents: props.disabled ? "none" : "auto",
                touchAction: props.disabled ? "none" : "auto"
              }
            }}
            onEnd={!props.disabled ? checkIfEmpty : undefined}
          />
        </Flex>
        {!props.disabled && (
          <Flex justify="end">
            <Button onClick={clearSignature} size="small">
              {props.t("common.clear")}
            </Button>
          </Flex>
        )}
      </Space>
    </Form.Item>
  );
};

export default withTranslation()(FormSignature);
