import { makeAutoObservable, toJS } from "mobx";
import { forkJoin } from "rxjs";
import { gql } from "@apollo/client";
import i18next from "i18next";
import BackendService from "../BackendService";
import GraphQlService from "../GraphQlService";
import { DisconnectOutlined } from "@ant-design/icons";

import _, { cloneDeep, filter, map } from "lodash";
import { Utils } from "@react-awesome-query-builder/antd";
import { message } from "antd";
class NotificationComponentStore {
  configApi = new BackendService("configs");
  usersApi = new BackendService("users");
  graphQlService = new GraphQlService();

  weeklyReportSettings = {
    isEnabled: false,
    startDate: undefined,
    repeatAmount: undefined,
    repeatTimeFrame: undefined,
    repeatDays: undefined,
    nextSendScheduled: undefined
  };

  isLoading = true;

  notifications = [];
  config = {};

  unreadCount = 0;
  steps = null;
  settingsModalVisible = false;

  constructor() {
    makeAutoObservable(this);
    this.loadList();
  }
  updateNotifications = () => {
    const watchRef = this.graphQlService.clientWs().watchQuery({
      fetchPolicy: "no-cache",
      query: gql`
        {
          __typename
        }
      `
    });
    watchRef.subscribeToMore({
      document: gql`subscription listener {notificationsUpdated(userId: "${localStorage.getItem(
        `id`
      )}", projectId: "${localStorage.getItem(`projectId`)}") {
        total
    }}`,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData?.data) return prev;
        this.setUnreadCount(subscriptionData.data.notificationsUpdated.total);
        return prev;
      }
    });
  };
  setNotifications = newNotifications => {
    this.notifications = newNotifications;
  };
  setUnreadCount = total => {
    this.unreadCount = total;
  };
  markAsRead = async ids => {
    const isNotReadNotifications = this.notifications.some(notification => {
      if (ids.includes(notification.id)) {
        return notification.payload.IsRead === false;
      }
      return false;
    });
    if (isNotReadNotifications) {
      try {
        const formattedIds = ids.map(id => `"${id}"`).join(", ");
        const idsList = `[${formattedIds}]`;

        const response = this.graphQlService.post(
          `mutation {
          notificationMarkAsRead(data: {ids: ${idsList}})
        }`
        );
        const data = await response;
        if (data?.data) {
          message.success(i18next.t("notifications.messages.successRead"));

          this.setNotifications(
            map(this.notifications, notification => {
              if (ids.includes(notification.id)) {
                notification.payload.IsRead = true;
                return notification;
              }
              return notification;
            })
          );

          return true;
        }
      } catch (error) {
        if (navigator.onLine) {
          message.error(i18next.t("notifications.messages.error", { error: error.message }));
        } else {
          message.open({
            content: (
              <div style={{ textAlign: "center" }}>
                <DisconnectOutlined style={{ fontSize: "24px", color: "#ff4d4f" }} />
              </div>
            ),
            duration: 3,
            icon: null
          });
        }
      }
    } else return;
  };
  deleteNotifications = async ids => {
    try {
      const formattedIds = ids.map(id => `"${id}"`).join(", ");
      const idsList = `[${formattedIds}]`;

      const response = this.graphQlService.post(
        `mutation {
          notificationDelete(data: {ids: ${idsList}})
        }`
      );
      const data = await response;

      if (data?.data) {
        this.setNotifications(
          filter(this.notifications, notification => {
            return !ids.includes(notification.id);
          })
        );

        message.success(i18next.t("notifications.messages.success"));
        return true;
      }
    } catch (error) {
      if (navigator.onLine) {
        message.error(i18next.t("notifications.messages.error", { error: error.message }));
      } else {
        message.open({
          content: (
            <div style={{ textAlign: "center" }}>
              <DisconnectOutlined style={{ fontSize: "24px", color: "#ff4d4f" }} />
            </div>
          ),
          duration: 3,
          icon: null
        });
      }
    }
  };

  loadList = async () => {
    this.isLoading = true;
    this.setUnreadCount(0);
    if (!localStorage.getItem(`jwtToken`) || !localStorage.getItem(`projectId`)) return;
    try {
      const notificationsResponse = await this.graphQlService.get(
        `{ notifications(projectId: "${localStorage.getItem(`projectId`)}") { id payload createdDateTime } }`
      );
      if (notificationsResponse && !notificationsResponse?.errors) {
        const notifications = notificationsResponse.data.notifications;
        _.forEach(notifications, notification => {
          if (typeof notification.payload === "string") notification.payload = JSON.parse(notification.payload || "{}");

          if (notification.payload["IsRead"] === undefined || notification.payload["IsRead"] === false) {
            const newUnreadCount = this.unreadCount + 1;
            this.setUnreadCount(newUnreadCount);
          }
        });
        this.setNotifications(notifications);

        this.isLoading = false;
      }
      const parsedNotificationsSettings = notificationsResponse.data.notifications;
      this.configs = parsedNotificationsSettings;
    } catch (error) {
      if (navigator.onLine) {
        message.info("You have been logged out");
      } else {
        message.open({
          content: (
            <div style={{ textAlign: "center" }}>
              <DisconnectOutlined style={{ fontSize: "24px", color: "#ff4d4f" }} />
            </div>
          ),
          duration: 3,
          icon: null
        });
      }
    }
  };

  saveSettings = () => {
    this.isLoading = true;
    var obj = cloneDeep(this.configs);
    obj["weeklyReportSettings"] = toJS(this.weeklyReportSettings);
    this.usersApi.put(`notifications/settings`, obj).then(r => {
      message.success("Settings have been updated!");
      this.isLoading = false;
    });
  };

  updateSettingCheckbox = (type, key, valueType, value) => {
    this.isLoading = true;
    this.configs[type].filter(x => x.key === key)[0][valueType] = value;
    this.isLoading = false;
  };

  getNotificationText = payload => {
    var text = ``;
    if (payload.Source) {
      text = payload.Text;
    } else {
      var url = ``;
      if (payload.StringValue2) {
        url = `{"id":"bba98b88-0123-4456-b89a-b170aef79de7","type":"group","children1":[`;
        url += payload.StringValue2.split(",").map(
          x =>
            `{"id": "${Utils.uuid()}", "type":"rule","properties":{"field":"Payload$Id","operator":"like","value":["${x}"],"valueSrc":["value"],"valueType":["text"]}}`
        );
        url += `], "properties":{"conjunction":"OR"}}`;
      }

      var definition = "";
      if (this.configs)
        definition = this.configs[payload.StringValue1].filter(
          x => x.key.toLowerCase() === payload.Type.toLowerCase()
        )[0];

      if (payload.Type && payload.Type === "USER_NOTIFICATION" && payload.Values) return payload.Values.Value1;

      if (!definition) return text;

      if (payload.IntValue1 > 1) text = definition["text"]["multiple"];
      else text = definition["text"]["single"];

      // eslint-disable-next-line no-template-curly-in-string
      text = text.replace("${url}", url);
      _.forEach(
        ["Intvalue1", "GuidValue1", "StringValue1", "StringValue2", "StringValue3", "StringValue4", "StringValue5"],
        payloadItem => {
          text = text.replace("${payload." + payloadItem + "}", payload[payloadItem]);
        }
      );
    }
    return text;
  };

  clearNotifications = () => {
    this.isLoading = true;
    this.usersApi.get(`notifications/clear`).then(() => {
      this.setNotifications([]);
      this.isLoading = false;
    });
  };
}

export default new NotificationComponentStore();
